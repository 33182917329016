import { graphql, StaticQuery } from "gatsby";
import React, {IframeHTMLAttributes, useEffect, useRef} from "react";
import "./technical-demo.scss";
import {
  getPage,
  TypeTechnicalDemoPage,
  TypeTechnicalDemoQueryResult,
} from "../../utils/queries/technical_demo";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";

const query = graphql`
  {
    allPrismicTechnicalDemo {
      edges {
        node {
          data {
            page_meta_description
            page_meta_thumbnail {
              alt
              url
            }
            title
            meta_title
            hero_image {
              alt
              url
            }
            subtitle {
              html
              raw
              text
            }
          }
        }
      }
    }
  }
`;

const TechnicalDemoQueryPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeTechnicalDemoQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicPricing",
    true
  ) as TypeTechnicalDemoPage;
  const title = result.meta_title ? result.meta_title : result.title;
  const iframeRef = useRef<HTMLIFrameElement>();

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.src = `${iframeRef.current.src}${location.search}`
    }
  }, [location])

  return (
    <AmazeePage location={location}>
      <div id="technical-demo">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <section className=" bg-amazee-solitude py-5">
          <iframe
            ref={iframeRef}
            src={`https://signup.ch3.amazee.io/dashboard`}
            style={{
              margin: '0 auto',
              display: 'block',
              height: '1100px',
              maxHeight: '1250px',
              width: '100%',
              overflowY: 'auto',
              border: 'none',
            }}
            />
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticTechnicalDemoQueryPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <TechnicalDemoQueryPage location={location} data={data} />
      )}
    />
  );
};
export default StaticTechnicalDemoQueryPage;
