import { PrismicImage, PrismicRichText } from "./types";

export type TypeTechnicalDemoPage = {
  title: string;
  meta_title: string;
  subtitle: PrismicRichText;
  hero_image: PrismicImage;
  page_meta_description: string;
  page_meta_thumbnail: PrismicImage;
};

export type TypeTechnicalDemoQueryResult = {
  allPrismicTechnicalDemo: {
    edges: Array<{
      node: { data: TypeTechnicalDemoPage };
    }>;
  };
};

export const getPage = (
  data: TypeTechnicalDemoQueryResult
): TypeTechnicalDemoPage => {
  return data.allPrismicTechnicalDemo.edges[0].node.data;
};
